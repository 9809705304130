* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
