.button {
  @extend .Yellow;
  box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.06);
  padding: 1rem;
  background-color: #1e7ea4;
  text-transform: uppercase;
  font-family: "Roboto";
  font-size: 1.6rem;
  border-radius: 1rem;
  margin: 1rem 0.5rem;
  cursor: pointer;
  outline: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  color: inherit;
  display: block;
  &:hover {
    box-shadow: none;
    transform: scale(1.05);
  }
}

.Desc {
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    padding: 0 2rem;
  }
}

.Iframe {
  width: calc(100% - 4rem);
  margin: 2rem auto;
  position: relative;
  padding-bottom: 56.25%;
  @media (min-width: 768px) {
    padding-bottom: 0;
    width: 70rem;
    height: 40rem;
  }
}

.Iframe iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.Description {
  max-width: 45rem;
  margin: 0 auto;
  font-size: 1.6rem;
  padding: 2rem;
}

.Yellow {
  background-color: #fff694;
}

.Koncert {
  padding: 0 2rem;
  padding-top: 25rem;
  max-width: 70rem;
  margin: 0 auto;

  p {
    font-family: "Roboto";
    font-size: 1.6rem;
    @media (min-width: 768px) {
      font-size: 1.8rem;
    }
  }

  .Koncert-fb {
    margin: 2rem 0;
    text-align: center;
  }
}

.Koncert-iframe {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  margin: 2rem 0;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.Koncert-karta {
  display: inline-block;
}
