.Hero {
  margin: 0 auto;
  background-image: url("../../assets/images/band.jpg");
  width: calc(100% - 4rem);
  height: 90vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
